import React from "react";
import { Link } from "react-router-dom";

const ProgramItem = ({ data, index, link }) => {
  if (data?.log?.isComplete) {
    return (
      <Link
        to={link}
        state={data}
        className="w-full h-12 px-10 flex hover:opacity-80"
      >
        <p className="w-10 my-auto text-lg text-textGreen text-left">{`# ${
          index + 1
        }`}</p>
        <p className="text-lg text-textGreen my-auto ml-5">{data?.title}</p>
      </Link>
    );
  } else {
    return (
      <Link
        to={link}
        state={data}
        className="w-full h-12 px-10 flex hover:opacity-80"
      >
        <p className="w-10 my-auto text-lg text-textRed text-left">{`# ${
          index + 1
        }`}</p>
        <p className="text-lg text-textRed my-auto ml-5">{data?.title}</p>
      </Link>
    );
  }
};

export default ProgramItem;
