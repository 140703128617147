import { useMediaQuery } from "react-responsive";

const Default = ({ children }) => {
  const isDefault = useMediaQuery({ minWidth: 1024 });
  return isDefault ? children : null;
};
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 1023 });
  return isMobile ? children : null;
};

const MediaQueries = { Default, Mobile };

export default MediaQueries;
