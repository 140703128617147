import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../api/API";
import Frame from "../../components/Frame";
import LoginCheck from "../../components/LoginCheck";
import { formatDate } from "../../utils/formater";

const AdminMain = () => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.userAuth);
  const [userList, setUserList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [noticeList, setNoticeList] = useState([]);

  useEffect(() => {
    if (accessToken) {
      API.getAdmins({ accessToken }).then((res) => {
        if (res.success) {
          const formatData = res.data.map((admin) => ({
            loginId: admin?.loginId,
            name: admin?.name,
            createdAt: formatDate(admin.createdAt, true),
          }));
          setAdminList(formatData);
        }
      });
      API.getUsers({ accessToken }).then((res) => {
        if (res.success) {
          const formatData = res.data.map((user) => ({
            loginId: user?.loginId,
            name: user?.name,
          }));

          setUserList(formatData);
        }
      });
      API.getAllCourse().then((res) => {
        if (res.success) {
          const formatData = res.data.map((course) => ({
            title: course.title,
            _id: course._id,
            createdAt: formatDate(course.createdAt, true),
          }));
          setCourseList(formatData);
        }
      });
      API.getAllNotice().then((res) => {
        if (res.success) {
          const formatData = res.data.map((notice) => ({
            title: notice.title,
            _id: notice._id,
            createdAt: formatDate(notice.createdAt, true),
          }));
          setNoticeList(formatData);
        }
      });
    }
  }, [accessToken]);

  return (
    <LoginCheck needAdmin={true}>
      <div className="w-[100vw] min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll mb-10">
        <Frame title="Admins" className="h-60 w-full lg:w-auto lg:mx-24">
          {adminList.length === 0 ? (
            <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
              Admins is Empty
            </p>
          ) : (
            adminList.map((admin, index) => (
              <div
                key={index}
                className="w-full h-10 flex my-1 hover:opacity-60"
              >
                <div className="w-full h-full">
                  <div className="w-full h-full flex flex-row">
                    <div className="my-auto ml-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {admin.name}
                      </p>
                    </div>
                    <div className="my-auto ml-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {admin.loginId}
                      </p>
                    </div>
                    <div className="my-auto ml-auto mr-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {admin.createdAt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Frame>
        <Frame
          title="Users"
          className="h-60 w-full lg:w-auto lg:mx-24"
          onPlusClick={() => {
            navigate("/admin/user-list");
          }}
        >
          {userList.length === 0 ? (
            <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
              Users is Empty
            </p>
          ) : (
            userList.map((user, index) => (
              <div
                key={index}
                className="w-full h-10 flex my-1 hover:opacity-60"
              >
                <div className="w-full h-full">
                  <div className="w-full h-full flex flex-row">
                    <div className="my-auto ml-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {user.name}
                      </p>
                    </div>
                    <div className="my-auto ml-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {user.loginId}
                      </p>
                    </div>
                    <div className="my-auto ml-auto mr-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {user.createdAt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Frame>
        <Frame
          title="Notices"
          className="h-60 w-full lg:w-auto lg:mx-24"
          onPlusClick={() => {
            navigate("/admin/notice-list");
          }}
        >
          {noticeList.length === 0 ? (
            <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
              Notices is Empty
            </p>
          ) : (
            noticeList.map((notice, index) => (
              <div
                key={index}
                className="w-full min-h-10 flex my-1 hover:opacity-60"
              >
                <div className="w-full h-full">
                  <div className="w-full h-full flex flex-row">
                    <div className="my-auto ml-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {notice.title}
                      </p>
                    </div>
                    <div className="my-auto ml-auto mr-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {notice.createdAt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Frame>
        <Frame
          title="Courses"
          className="h-60 w-full lg:w-auto lg:mx-24"
          onPlusClick={() => {
            navigate("/admin/course-list");
          }}
        >
          {courseList.length === 0 ? (
            <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
              Courses is Empty
            </p>
          ) : (
            courseList.map((course, index) => (
              <div
                key={index}
                className="w-full min-h-10 flex my-1 hover:opacity-60"
              >
                <div className="w-full h-full">
                  <div className="w-full h-full flex flex-row">
                    <div className="my-auto ml-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {course.title}
                      </p>
                    </div>
                    <div className="my-auto ml-auto mr-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {course.createdAt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Frame>
      </div>
    </LoginCheck>
  );
};

export default AdminMain;
