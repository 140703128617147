import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { API } from "../../api/API";
import SlideView from "../../components/SlideView";
import MediaQueries from "../../components/query/MediaQueries";
import Frame from "../../components/Frame";
import { formatDate } from "../../utils/formater";

const Main = () => {
  const newsDummy = [
    { img: "/imgs/sample_banner1.png" },
    { img: "/imgs/sample_banner2.jpg" },
    { img: "/imgs/sample_banner3.jpg" },
    { img: "/imgs/sample_banner4.jpg" },
  ];

  const [noticeList, setNoticeList] = useState([]);
  const [courseList, setCourseList] = useState([]);

  const newsRenderer = (item, index) => {
    return (
      <a
        key={index}
        href={item?.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="w-full h-full bg-background overflow-hidden">
          <img
            src={item?.img}
            width={"100%"}
            height={"100%"}
            alt={item?.link}
          />
        </div>
      </a>
    );
  };

  useEffect(() => {
    API.getAllCourse().then((res) => {
      if (res.success) {
        const formatData = res.data.map((course) => ({
          title: course.title,
          banner: course.banner,
          _id: course._id,
        }));
        setCourseList(formatData);
      }
    });
    API.getAllNotice().then((res) => {
      if (res.success) {
        const formatData = res.data.map((notice) => ({
          title: notice.title,
          _id: notice._id,
          createdAt: formatDate(notice.createdAt),
        }));
        setNoticeList(formatData);
      }
    });
  }, []);

  return (
    <div className="w-[100vw] min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll">
      <div className="w-[100vw] h-[25vw] flex overflow-hidden">
        <SlideView renderer={newsRenderer} data={newsDummy} dotSize={4} />
      </div>
      <Frame title="Notice" className="h-80 w-full lg:w-auto lg:mx-24">
        {noticeList.length === 0 ? (
          <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
            Notice is Empty
          </p>
        ) : (
          noticeList.map((item, index) => (
            <div key={index} className="w-full h-10 flex my-1 hover:opacity-60">
              <Link to={`/notice/${item._id}`} className="w-full h-full">
                <div className="w-full h-full flex flex-row">
                  <div className="ml-5 my-auto bg-textGreen w-5 h-5" />
                  <div className="my-auto ml-10">
                    <p className="text-textGreen text-2xl">{item.title}</p>
                  </div>
                  <div className="my-auto ml-auto mr-5">
                    <p className="text-textGreen text-2xl">{item.createdAt}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))
        )}
      </Frame>
      <div className="my-10 lg:mx-24 flex flex-col bg-background lg:border-backGreen lg:border-4">
        <MediaQueries.Default>
          <div className="h-16 w-full bg-backGreen flex">
            <p className="text-2xl text-textYellow my-auto ml-3">Contents</p>
          </div>
        </MediaQueries.Default>
        <div className="mt-10 grid justify-items-center grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-y-20 pb-10">
          {courseList.map((item, index) => (
            <div>
              <Link
                to={`/course/${item._id}`}
                key={index}
                className="w-[100vw] lg:w-96 h-[62.5vw] lg:h-60 hover:shadow-lg flex flex-col border-4 bg-background border-backGreen"
              >
                <div className="min-h-10 w-full bg-backGreen">
                  <p className="text-2xl text-textYellow my-auto ml-3 break-all">
                    {item.title}
                  </p>
                </div>
                <div className="w-full h-full overflow-hidden">
                  <img
                    src={item.banner}
                    width={"100%"}
                    height={"100%"}
                    alt={item.title}
                  />
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Main;
