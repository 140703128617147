import { Fragment } from "react";

const Modal = ({ children, modalState }) => {
  const [state, setState] = modalState;

  const outsideClick = (e) => {
    if (e.target === e.currentTarget) {
      setState(false);
    }
  };

  if (!state) {
    return <Fragment />;
  } else {
    return (
      <div
        className="bg-black bg-opacity-30
                    w-full h-full 
                    z-10 fixed flex
                    top-0 left-0"
        onClick={outsideClick}
      >
        <div
          className="bg-background
                    rounded-t-2xl lg:rounded-t-none
                    flex lg:absolute
                    z-20
                    lg:top-[50%] lg:left-[50%]
                    lg:translate-y-[-50%] lg:translate-x-[-50%]
                    mt-auto lg:mt-0
                    w-full lg:w-auto
                    shadow-2xl"
        >
          {children}
        </div>
      </div>
    );
  }
};

export default Modal;
