import { useState } from "react";
import PlusIcon from "./icons/PlusIcon";
import SearchIcon from "./icons/SearchIcon";
import UserQueries from "./query/UserQueries";

const Frame = ({ title, className, onPlusClick, searchState, children }) => {
  const defaultState = useState("");
  const [search, setSearch] = searchState ? searchState : defaultState;

  return (
    <div
      className={`${className} mt-10 mx-auto flex flex-col bg-background border-backGreen border-4`}
    >
      <div className="min-h-10 lg:h-16 w-full bg-backGreen flex">
        <p className="text-lg lg:text-2xl text-textYellow my-auto ml-3">
          {title}
        </p>
        {searchState && (
          <div className="border-b-2 border-textYellow my-5 ml-5">
            <button>
              <SearchIcon className="stroke-textYellow w-4 h-4 lg:w-6 lg:h-6" />
            </button>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="text-lg w-16 sm:w-32 lg:text-2xl lg:w-80 text-textYellow px-1 lg:px-3 outline-none bg-transparent"
            />
          </div>
        )}
        {onPlusClick && (
          <UserQueries.NeedAdmin>
            <button
              onClick={onPlusClick}
              className="my-auto ml-auto hover:opacity-60"
            >
              <PlusIcon className={"stroke-textBlue"} />
            </button>
          </UserQueries.NeedAdmin>
        )}
      </div>
      <div className="w-full h-full overflow-y-scroll flex flex-col">
        {children}
      </div>
    </div>
  );
};

export default Frame;
