import siteInfo from "../data/siteInfo";

const OutLink = ({ id, text, link }) => {
  return (
    <a
      key={id}
      href={link}
      className="my-auto"
      target="_blank"
      rel="noopener noreferrer"
    >
      <p className="text-lg lg:text-2xl text-textYellow">{text}</p>
    </a>
  );
};

const IconLink = ({ id, img, link, className, width, height }) => {
  return (
    <a
      key={id}
      href={link}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img alt="icon" src={img} width={width} height={height} />
    </a>
  );
};

const Footer = () => {
  return (
    <div className="min-h-64 w-full bg-backBlue">
      <div className="h-20 mx-5 lg:mx-24 flex flex-row">
        <div className="flex flex-row space-x-3 lg:space-x-5">
          <OutLink
            id="privacy-policy"
            text="Privacy Policy"
            link="https://www.pusan.ac.kr/kor/CMS/Board/Board.do?mCode=MN186"
          />
        </div>
        <div className="flex flex-row lg:ml-auto ml-3">
          {/*<IconLink
            id={"youtube"}
            className="my-auto"
            img={"/imgs/youtube.png"}
            link={"https://youtube.com/"}
            width={40}
          />*/}
        </div>
      </div>
      <div className="border-b-4 border-textBlue w-full" />
      <div className="min-h-36 mx-5 lg:mx-24 flex flex-col lg:flex-row py-3 justify-between">
        <div className="h-full flex flex-col mr-5">
          <div className="flex flex-col">
            <p className="text-textYellow text-sm lg:text-lg">
              ADDRESS : {siteInfo.address}
            </p>
            <p className="text-textYellow text-sm lg:text-lg">
              TEL : {siteInfo.telephon}
            </p>
            <p className="text-textYellow text-sm lg:text-lg">
              EMAIL : {siteInfo.email}
            </p>
          </div>
          <p className="my-3 text-textYellow text-sm lg:text-lg">
            {"COPTRIGHT (C) PUSAN NATIONAL UNIVERSITY. All rights reaerved."}
          </p>
        </div>
        <IconLink
          id={"youtube"}
          className="lg:my-auto my-3 lg:ml-auto"
          img={"/imgs/koica.png"}
          link={"https://www.koica.go.kr/"}
          width={200}
        />
        <IconLink
          id={"youtube"}
          className="lg:my-auto lg:ml-5"
          img={"/imgs/pnu.png"}
          link={"https://www.pusan.ac.kr/"}
          width={100}
        />
      </div>
    </div>
  );
};

export default Footer;
