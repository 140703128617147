import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../api/API";
import Frame from "../../components/Frame";
import LoginCheck from "../../components/LoginCheck";
import { formatDate } from "../../utils/formater";

const NoticeList = () => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.userAuth);
  const [page] = useState(1);
  const searchTitleState = useState("");
  const [searchTitle] = searchTitleState;
  const [noticeList, setNoticeList] = useState([]);

  useEffect(() => {
    if (accessToken) {
      API.getAllNotice().then((res) => {
        if (res.success) {
          const formatData = res.data.map((notice) => ({
            title: notice.title,
            _id: notice._id,
            createdAt: formatDate(notice.createdAt, true),
          }));
          setNoticeList(
            formatData.filter((notice) => notice.title.includes(searchTitle))
          );
        }
      });
    }
  }, [accessToken, searchTitle]);

  return (
    <LoginCheck needAdmin={true}>
      <div className="w-[100vw] min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll mb-10">
        <Frame
          title={`Notices ${page} page`}
          className="w-full lg:w-auto lg:mx-24"
          searchState={searchTitleState}
          onPlusClick={() => {
            navigate("/notice/create");
          }}
        >
          {noticeList.length === 0 ? (
            <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
              Notice is Empty
            </p>
          ) : (
            noticeList.map((notice, index) => (
              <div
                key={index}
                className="w-full min-h-10 flex my-1 hover:opacity-60"
              >
                <Link to={`/notice/${notice._id}`} className="w-full h-full">
                  <div className="w-full h-full flex flex-row">
                    <div className="my-auto ml-10">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {notice.title}
                      </p>
                    </div>
                    <div className="my-auto ml-auto mr-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {notice.createdAt}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          )}
        </Frame>
      </div>
    </LoginCheck>
  );
};

export default NoticeList;
