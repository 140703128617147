import { Fragment } from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../utils/formater";
import EditIcon from "./icons/EditIcon";
import TrashIcon from "./icons/TrashIcon";

const Comment = ({ comment, onEdit, onDelete }) => {
  const { id } = useSelector((state) => state.userAuth);

  return (
    <div className="bg-background my-5 flex flex-col border-4 border-backGreen">
      <p className="px-3 text-2xl text-textYellow w-full bg-backGreen">
        {comment?.writerLoginId}
      </p>
      <div className="flex flex-row">
        <div className="mx-5 w-full">
          <p className="text-xl text-textGreen whitespace-pre-line break-words">
            {comment?.contents}
          </p>
        </div>
        {id === comment.writerId && (
          <Fragment>
            <button
              className="ml-auto mb-auto hover:opacity-60"
              onClick={() => {}}
            >
              <EditIcon className={"w-6 h-6 stroke-textBlue"} />
            </button>
            <button
              className="ml-2 mb-auto hover:opacity-60"
              onClick={() => {
                if (onDelete) onDelete(comment);
              }}
            >
              <TrashIcon className={"stroke-textRed"} />
            </button>
          </Fragment>
        )}
      </div>
      <p className="text-sm ml-auto text-textGreen">
        {formatDate(comment?.createdAt)}
      </p>
    </div>
  );
};

export default Comment;
