import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import PageNotFound from "../pages/error/PageNotFound";
import Main from "../pages/main/Main";
import AdminRoute from "./AdminRoute";
import AuthRoute from "./AuthRoute";
import CourseRoute from "./CourseRoute";
import ErrorRoute from "./ErrorRoute";
import NoticeRoute from "./NoticeRoute";

const AppRoute = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/">
          <Route index element={<Main />} />
        </Route>
        <Route path="/error/*" element={<ErrorRoute />} />
        <Route path="/auth/*" element={<AuthRoute />} />
        <Route path="/course/*" element={<CourseRoute />} />
        <Route path="/admin/*" element={<AdminRoute />} />
        <Route path="/notice/*" element={<NoticeRoute />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default AppRoute;
