import { ajax } from "./API";

const createProgram = async ({
  accessToken,
  courseId,
  title,
  description,
  video,
}) => {
  try {
    const options = {
      method: "POST",
      url: `/auth/program/create`,
      headers: {
        Authorization: accessToken,
      },
      data: {
        title,
        courseId,
        description,
        video,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const endProgram = async ({ programId, accessToken, progress }) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/program/end/${programId}`,
      headers: {
        Authorization: accessToken,
      },
      data: {
        progress,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const completeProgram = async ({ programId, accessToken }) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/program/complete/${programId}`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const updateProgram = async ({
  programId,
  accessToken,
  title,
  description,
  video,
}) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/program/${programId}`,
      headers: {
        Authorization: accessToken,
      },
      data: {
        title,
        description,
        video,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const deleteProgram = async ({ programId, accessToken }) => {
  try {
    const options = {
      method: "DELETE",
      url: `/auth/program/${programId}`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const ProgramAPI = {
  createProgram,
  endProgram,
  completeProgram,
  updateProgram,
  deleteProgram,
};

export default ProgramAPI;
