import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import YouTube from "react-youtube";
import { API } from "../../api/API";
import { formatYoutube } from "../../utils/formater";
import Modal from "../Modal";
import UserQueries from "../query/UserQueries";

const ProgramAddModal = ({ modalState, courseId }) => {
  const { accessToken } = useSelector((state) => state.userAuth);

  const [, setOpen] = modalState;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState("");
  const [preview, setPreview] = useState("");
  const [msg, setMsg] = useState("");

  const titleInp = useRef();
  const descriptionInp = useRef();
  const videoInp = useRef();

  const reset = () => {
    setTitle("");
    setDescription("");
    setVideo("");
    setPreview("");
    setMsg("");
  };

  const createProgram = async () => {
    await API.createProgram({
      accessToken,
      courseId,
      title: title,
      description: description,
      video: formatYoutube(video),
    }).then((res) => {
      if (res.success) {
      }
      reset();
      setOpen(false);
    });
  };

  const titleKeyPress = (key) => {
    if (key === "Enter") {
      videoInp.current.focus();
    }
  };

  const videoKeyPress = (key) => {
    if (key === "Enter") {
      descriptionInp.current.focus();
    }
  };

  const pressAddBtn = () => {
    createProgram();
  };

  const pressPreviewBtn = () => {
    if (video === "") setMsg("Need YouTuBe Link");
    else setPreview(formatYoutube(video));
  };

  return (
    <UserQueries.NeedAdmin>
      <Modal modalState={modalState}>
        <div className="w-full lg:w-[60vw] h-[90vh] lg:h-[50vh] flex flex-col lg:flex-row overflow-y-scroll">
          <div className="w-full lg:w-[35vw] mt-20 lg:mt-0 h-full flex flex-col">
            <div className="bg-backBlue w-[90vw] lg:w-[30vw] h-[50.625vw] lg:h-[16.8vw] mx-auto lg:my-auto">
              <YouTube
                key={preview ? preview : "null"}
                className="lg:w-[30vw] lg:h-[16.8vw] w-[90vw] h-[50.625vw]"
                videoId={preview}
                opts={{
                  width: "100%",
                  height: "100%",
                  playerVars: {
                    autoplay: 1,
                    rel: 0,
                    modestbranding: 1,
                  },
                }}
              />
            </div>
          </div>
          <div className="lg:w-[20vw] lg:h-[35vh] flex flex-col mt-4 lg:my-auto lg:mr-auto px-4 lg:px-0">
            <p className="text-2xl text-textGreen">Title</p>
            <input
              ref={titleInp}
              onKeyPress={(e) => titleKeyPress(e.key)}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="px-3 border-b-backGreen border-b-4 outline-none text-xl text-textGreen"
            />
            <p className="text-2xl text-textGreen">Link</p>
            <input
              ref={videoInp}
              value={video}
              onFocus={() => setVideo("")}
              onKeyPress={(e) => videoKeyPress(e.key)}
              onChange={(e) => setVideo(e.target.value)}
              className="px-3 border-b-backGreen border-b-4 outline-none text-xl text-textGreen"
            />
            <p className="text-2xl text-textGreen">Description</p>
            <textarea
              ref={descriptionInp}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="h-full resize-none py-1 px-3 border-backGreen border-4 outline-none text-xl text-textGreen"
            />
            <p className="text-xl text-textRed w-full h-28">{msg}</p>
            <div className="flex flex-row w-full h-20 mb-5 lg:mb-0 lg:h-10">
              <button
                onClick={pressPreviewBtn}
                className="w-[48%] h-full bg-backGreen lg:mt-2"
              >
                <p className="m-auto text-xl font-bold text-textYellow">
                  Preview
                </p>
              </button>
              <button
                onClick={pressAddBtn}
                className="w-[48%] h-full bg-backGreen lg:mt-2 ml-auto"
              >
                <p className="m-auto text-xl font-bold text-textYellow">ADD</p>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </UserQueries.NeedAdmin>
  );
};

export default ProgramAddModal;
