import React from "react";
import { Route, Routes } from "react-router-dom";
import NeedAdminError from "../pages/error/NeedAdminError";
import PageNotFound from "../pages/error/PageNotFound";

const ErrorRoute = () => {
  return (
    <Routes>
      <Route path="/need-admin" element={<NeedAdminError />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default ErrorRoute;
