import React from "react";

const PageNotFound = () => {
  return (
    <div className="w-[100vw] min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll">
      <p className="text-pointRed text-3xl m-auto">Page Not Found</p>
    </div>
  );
};

export default PageNotFound;
