import { ajax } from "./API";

const createNotice = ({ accessToken, title, contents }) => {
  try {
    const options = {
      method: "POST",
      url: `/auth/notice/create`,
      headers: {
        Authorization: accessToken,
      },
      data: {
        title,
        contents,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
const getAllNotice = () => {
  try {
    const options = {
      method: "GET",
      url: `/auth/notice/getAll`,
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
const getNotice = ({ noticeId }) => {
  try {
    const options = {
      method: "GET",
      url: `/auth/notice/${noticeId}`,
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
const updateNotice = ({ accessToken, noticeId, title, contents }) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/notice/${noticeId}`,
      headers: {
        Authorization: accessToken,
      },
      data: {
        title,
        contents,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
const deleteNotice = ({ accessToken, noticeId }) => {
  try {
    const options = {
      method: "DELETE",
      url: `/auth/notice/${noticeId}`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
const NoticeAPI = {
  createNotice,
  getAllNotice,
  getNotice,
  updateNotice,
  deleteNotice,
};

export default NoticeAPI;
