import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom";
import MediaQueries from "./query/MediaQueries";
import ProgramItem from "./ProgramItem";
import { useState } from "react";
import SlideOpenIcon from "./icons/SlideOpenIcon";
import SlideCloseIcon from "./icons/SlideCloseIcon";
import { useEffect } from "react";
import Modal from "./Modal";

const ProgramSideViewOpener = ({ children, openState }) => {
  const [open, setOpen] = openState;
  return (
    <Fragment>
      <MediaQueries.Default>{children}</MediaQueries.Default>
      <MediaQueries.Mobile>
        <Fragment>
          {open ? (
            <Modal modalState={openState}>
              <Fragment>
                {children}
                <button
                  onClick={() => setOpen((prev) => !prev)}
                  className="bg-point2 w-10 h-10 fixed rounded-full shadow-xl top-24 left-2"
                >
                  <SlideCloseIcon className="stroke-point4 m-auto" />
                </button>
              </Fragment>
            </Modal>
          ) : (
            <button
              onClick={() => setOpen((prev) => !prev)}
              className="bg-point2 w-10 h-10 fixed rounded-full shadow-xl top-24 left-2"
            >
              <SlideOpenIcon className="stroke-point4 m-auto" />
            </button>
          )}
        </Fragment>
      </MediaQueries.Mobile>
    </Fragment>
  );
};

const ProgramSideView = () => {
  const openState = useState(false);
  const [, setOpen] = openState;

  const { courseId } = useParams();

  const location = useLocation();
  const { courseTitle, courseProgress, programs } = location.state;

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Fragment>
      <ProgramSideViewOpener openState={openState}>
        <div className="lg:absolute h-[100vh] px-3 w-full lg:h-full lg:w-[27rem] flex flex-col bg-background">
          <div className="bg-background">
            <div className="w-full h-0 border-b-4 border-backGreen mt-5" />
            <div className="w-full min-h-20 py-3 flex">
              <p className="m-auto text-2xl text-textGreen break-all">
                {courseTitle}
              </p>
            </div>
            <div className="w-full h-0 border-b-4 border-backGreen mb-5" />
            <div className="w-full flex h-8 border-4 border-backGreen bg-background relative">
              <div
                className="h-full bg-backGreen flex"
                style={{ width: `${courseProgress}%` }}
              />
              <p className="text left-[45%] text-textBlue absolute">
                {courseProgress}%
              </p>
            </div>
          </div>
          <div className="my-10 bg-background w-full h-full border-4 border-backGreen overflow-y-scroll">
            <div className="w-full h-8 bg-backGreen px-4 text-lg">
              <p className="text-textYellow">Program</p>
            </div>
            <div className="flex flex-col w-full">
              {programs?.map((data, index) => (
                <ProgramItem
                  data={{ ...data, courseTitle, courseProgress, programs }}
                  index={index}
                  link={`/course/${courseId}/program`}
                />
              ))}
            </div>
          </div>
        </div>
      </ProgramSideViewOpener>
    </Fragment>
  );
};

export default ProgramSideView;
