import React, { Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../api/API";
import { loginUser } from "../../reducer/userAuthSlice";
import MediaQueris from "../../components/query/MediaQueries";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAuth = useSelector((state) => state.userAuth);

  const [id, setId] = useState("");
  const [pwd, setPwd] = useState("");
  const [name, setName] = useState("");
  const [msg, setMsg] = useState("");

  const idInp = useRef();
  const pwdInp = useRef();
  const nameInp = useRef();

  const clickRegister = () => {
    if (id === "") setMsg("Need Id");
    else if (pwd === "") setMsg("Need Password");
    else if (name === "") setMsg("Need Name");
    else {
      API.register({ loginId: id, password: pwd, name: name }).then((res) => {
        if (res.success) {
          setMsg("");
          dispatch(
            loginUser({
              id: res.data.userId,
              loginId: res.data.loginId,
              accessToken: res.data.accessToken,
            })
          );
          navigate("/", { replace: true });
        } else {
          setMsg(res.data.message);
        }
      });
    }
  };

  const idKeyPress = (key) => {
    if (key === "Enter") {
      pwdInp.current.focus();
    }
  };

  const pwdKeyPress = (key) => {
    if (key === "Enter") {
      nameInp.current.focus();
    }
  };

  const nameKeyPress = (key) => {
    if (key === "Enter") {
      clickRegister();
    }
  };

  useEffect(() => {
    if (userAuth.isLogin) navigate("/", { replace: true });
  });

  if (userAuth.isLogin) return <Fragment />;
  else
    return (
      <div className="w-[100vw] min-h-[calc(100vh-20rem-2px)] flex flex-col bg-background">
        <div className="mx-auto md:w-[48rem] h-96 my-auto flex flex-col bg-background border-4 border-backGreen">
          <MediaQueris.Default>
            <div className="h-14 w-full flex bg-backGreen">
              <p className="text-4xl m-auto text-textYellow">Join</p>
            </div>
          </MediaQueris.Default>
          <div className="flex flex-row mt-10">
            <p className="text-2xl w-28 lg:text-4xl text-textGreen lg:w-36 text-right">
              ID :
            </p>
            <input
              className="w-full h-8 lg:h-10 ml-3 mr-5 border-b-4 border-backGreen text-2xl lg:text-4xl outline-none text-textGreen"
              onChange={(e) => setId(e.target.value)}
              value={id}
              ref={idInp}
              onKeyPress={(e) => idKeyPress(e.key)}
            />
          </div>
          <div className="flex flex-row mt-10">
            <p className="text-2xl w-28 lg:text-4xl text-textGreen lg:w-36 text-right">
              PWD :
            </p>
            <input
              className="w-full h-8 lg:h-10 ml-3 mr-5 border-b-4 border-backGreen text-2xl lg:text-4xl outline-none text-textGreen"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              ref={pwdInp}
              onKeyPress={(e) => pwdKeyPress(e.key)}
              type={"password"}
            />
          </div>
          <div className="flex flex-row mt-10">
            <p className="text-2xl w-28 lg:text-4xl text-textGreen lg:w-36 text-right">
              NAME :
            </p>
            <input
              className="w-full h-8 lg:h-10 ml-3 mr-5 border-b-4 border-backGreen text-2xl lg:text-4xl outline-none text-textGreen"
              onChange={(e) => setName(e.target.value)}
              value={name}
              ref={nameInp}
              onKeyPress={(e) => nameKeyPress(e.key)}
            />
          </div>
          <button className="bg-backGreen m-auto w-32" onClick={clickRegister}>
            <p className="mx-auto my-2 text-xl lg:text-3xl text-textYellow">
              Join
            </p>
          </button>
        </div>
      </div>
    );
};

export default Register;
