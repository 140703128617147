import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../api/API";
import InputTitleFrame from "../../components/InputTitleFrame";

const EditNotice = () => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.userAuth);
  const { noticeId } = useParams();
  const [title, setTitle] = useState("");
  const [contents, setContents] = useState("");
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const pressEditBtn = async () => {
    setLoading(true);
    if (title === "") {
      setMsg("Need Title");
    } else if (contents === "") {
      setMsg("Need Contents");
    } else {
      await API.updateNotice({ accessToken, title, contents, noticeId }).then(
        (res) => {
          if (res.success) {
            navigate(`/notice/${noticeId}`, { replace: true });
          } else {
            setMsg("Edit Fail");
          }
        }
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    if (accessToken) {
      API.getNotice({ noticeId }).then((res) => {
        if (res.success) {
          setTitle(res.data.title);
          setContents(res.data.contents);
        }
      });
    }
  }, [noticeId, accessToken]);

  return (
    <div className="w-screen min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll mb-10">
      <InputTitleFrame
        title={title}
        setTitle={setTitle}
        className="w-full lg:w-auto lg:mx-24"
      >
        <div className="p-5 w-full min-h-[40rem] flex flex-col">
          <textarea
            value={contents}
            onChange={(e) => setContents(e.target.value)}
            placeholder="Input Contents"
            className="resize-none h-[37.5rem] w-full bg-transparent border-0 outline-none text-lg lg:text-2xl text-textGreen placeholder-textGreen placeholder-shown:opacity-60"
          />
        </div>
      </InputTitleFrame>
      <div className="w-full lg:w-auto lg:mx-24 flex flex-col mt-5">
        <p className="text-lg lg:text-2xl text-textRed ml-auto">{msg}</p>
        <button
          disabled={loading}
          onClick={pressEditBtn}
          className="mt-5 ml-auto w-32 bg-backGreen py-2 hover:bg-opacity-60"
        >
          <p className="m-auto text-2xl font-bold text-textYellow">Edit</p>
        </button>
      </div>
    </div>
  );
};

export default EditNotice;
