const InputTitleFrame = ({ title, setTitle, className, children }) => {
  return (
    <div
      className={`${className} mt-10 mx-auto flex flex-col bg-background border-backGreen border-4`}
    >
      <div className="min-h-10 lg:h-16 w-full bg-backGreen flex">
        <input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Input Title"
          className="w-full text-lg lg:text-2xl placeholder-textYellow placeholder-shown:opacity-60 text-textYellow my-auto ml-3 outline-none bg-transparent"
        />
      </div>
      <div className="w-full h-full overflow-y-scroll flex flex-col">
        {children}
      </div>
    </div>
  );
};

export default InputTitleFrame;
