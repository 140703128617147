import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../api/API";

export const NoLogin = ({ children }) => {
  const userAuth = useSelector((state) => state.userAuth);
  return userAuth?.isLogin ? null : children;
};

export const NeedLogin = ({ children }) => {
  const userAuth = useSelector((state) => state.userAuth);
  return userAuth?.isLogin ? children : null;
};

export const NeedAdmin = ({ children }) => {
  const userAuth = useSelector((state) => state.userAuth);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    API.checkAdmin({ accessToken: userAuth?.accessToken }).then((res) => {
      if (res.success && res.data) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    });
  });
  return isAdmin ? children : null;
};

const UserQueries = { NoLogin, NeedLogin, NeedAdmin };

export default UserQueries;
