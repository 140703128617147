import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlayView = autoPlay(SwipeableViews);

const SlideView = ({ renderer, data, dotSize }) => {
  const [newsPage, setNewsPage] = useState(0);

  return (
    <div className="relative">
      <AutoPlayView
        index={newsPage}
        onChangeIndex={(index) => setNewsPage(index)}
        enableMouseEvents
      >
        {data.map((item, index) => renderer(item, index))}
      </AutoPlayView>
      <div className="absolute flex bottom-8 left-[50%] translate-x-[-50%]">
        {data.map((item, index) => (
          <button
            key={index}
            onClick={() => setNewsPage(index)}
            className={`${
              newsPage === index ? "bg-backYellow" : "bg-backBlue"
            } rounded-full w-4 h-4 mx-1 hover:opacity-80`}
          />
        ))}
      </div>
    </div>
  );
};

export default SlideView;
