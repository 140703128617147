import { Route, Routes } from "react-router-dom";
import PageNotFound from "../pages/error/PageNotFound";
import CreateNotice from "../pages/notice/CreateNotice";
import EditNotice from "../pages/notice/EditNotice";
import NoticeView from "../pages/notice/NoticeView";

const NoticeRoute = () => {
  return (
    <Routes>
      <Route path=":noticeId" element={<NoticeView />} />
      <Route path=":noticeId/edit" element={<EditNotice />} />
      <Route path="/create" element={<CreateNotice />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default NoticeRoute;
