import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearUser } from "../reducer/userAuthSlice";
import UserQueries from "./query/UserQueries";

const MenuItem = ({ id, text, link, onClick }) => {
  return (
    <div key={id}>
      {link ? (
        <button>
          <Link to={link}>
            <p className="my-auto text-sm lg:text-xl text-textYellow">{text}</p>
          </Link>
        </button>
      ) : (
        <button onClick={onClick}>
          <p className="my-auto text-sm lg:text-xl text-textYellow">{text}</p>
        </button>
      )}
    </div>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [, setOpenMenu] = useState(false);

  const logOut = () => {
    dispatch(clearUser());
    navigate("/", { replace: true });
  };

  useEffect(() => {
    setOpenMenu(false);
  }, [location]);

  return (
    <div className="w-full bg-backGreen h-20 px-5 lg:px-24">
      <div className="h-full flex flex-row">
        <div className="flex my-auto">
          <Link to="/" className="flex mt-auto">
            <p className="mt-auto text-lg lg:text-4xl font-bold text-textBlue">
              PNU4Ethi
            </p>
          </Link>
        </div>
        <div className="ml-auto my-auto flex flex-row space-x-2 lg:space-x-10">
          <MenuItem id={"home"} text={"Home"} link={"/"} />
          <UserQueries.NoLogin>
            <MenuItem id={"login"} text={"Login"} link={"/auth/login"} />
            <MenuItem id={"join"} text={"Join"} link={"/auth/register"} />
          </UserQueries.NoLogin>
          <UserQueries.NeedAdmin>
            <MenuItem id={"admin"} text={"Admin"} link={"/admin"} />
          </UserQueries.NeedAdmin>
          <UserQueries.NeedLogin>
            <MenuItem id={"logout"} text={"LogOut"} onClick={logOut} />
          </UserQueries.NeedLogin>
        </div>
      </div>
    </div>
  );
};

export default Header;
