import AppRoute from "./routes/AppRoute";

const App = () => {
  return (
    <div className="App bg-background">
      <AppRoute />
    </div>
  );
};

export default App;
