import React, { useEffect, useState } from "react";
import FileResizer from "react-image-file-resizer";

const ImageSelector = ({
  className,
  image,
  setImage,
  resize,
  disabled = false,
}) => {
  const [preview, setPreview] = useState(null);

  const onLoadFile = (e) => {
    FileResizer.imageFileResizer(
      e.target.files[0],
      resize.w,
      resize.h,
      "PNG",
      100,
      0,
      (file) => {
        setImage(file);
      },
      "file"
    );
  };

  useEffect(() => {
    if (image) {
      if (typeof image === "string") {
        setPreview(image);
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(image);
      }
    }
  }, [image]);

  return (
    <div
      className={"group " + (disabled ? "" : "hover:opacity-60 ") + className}
    >
      <label className="w-full h-full flex">
        {preview && <img src={preview} className="w-full object-cover" />}
        <input
          disabled={disabled}
          className="hidden"
          type="file"
          id="image"
          accept="img/*"
          onChange={onLoadFile}
        />
      </label>
    </div>
  );
};

export default ImageSelector;
