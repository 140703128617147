import { ajax } from "./API";

const createCocomment = async ({ accessToken, commentId, contents }) => {
  try {
    const options = {
      method: "POST",
      url: `/auth/cocoment/create`,
      data: { commentId, contents },
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const updateCocomment = async ({
  accessToken,
  cocommentId,
  writerId,
  commentId,
  contents,
}) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/cocoment/${cocommentId}`,
      data: { writerId, commentId, contents },
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const deleteCocomment = async ({ cocommentId, accessToken }) => {
  try {
    const options = {
      method: "DELETE",
      url: `/auth/cocoment/${cocommentId}`,
      headers: { Authorization: accessToken },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const CocommentAPI = {
  createCocomment,
  updateCocomment,
  deleteCocomment,
};

export default CocommentAPI;
