import { Route, Routes } from "react-router-dom";
import AdminMain from "../pages/admin/AdminMain";
import CourseList from "../pages/admin/CourseList";
import NoticeList from "../pages/admin/NoticeList";
import UserList from "../pages/admin/UserList";
import PageNotFound from "../pages/error/PageNotFound";

const AdminRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<AdminMain />} />
      {/*<Route path="/admin-list" element={<AdminList />} />*/}
      <Route path="/user-list" element={<UserList />} />
      <Route path="/course-list" element={<CourseList />} />
      <Route path="/notice-list" element={<NoticeList />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AdminRoute;
