import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateCourse from "../pages/course/CreateCourse";
import Lobby from "../pages/course/Lobby";
import ProgramView from "../pages/course/ProgramView";
import PageNotFound from "../pages/error/PageNotFound";

const CourseRoute = () => {
  return (
    <Routes>
      <Route path=":courseId" element={<Lobby />} />
      <Route path=":courseId/program" element={<ProgramView />} />
      <Route path="create" element={<CreateCourse />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default CourseRoute;
