import { ajax } from "./API";

const getProgramComments = async ({ accessToken, programId }) => {
  try {
    const options = {
      method: "GET",
      url: `/auth/programComment/${programId}`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const createProgramComment = async ({ accessToken, programId, contents }) => {
  try {
    const options = {
      method: "POST",
      url: `/auth/programComment/create`,
      data: { programId, contents },
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const updateProgramComment = async ({
  accessToken,
  programCommentId,
  writerId,
  programId,
  contents,
}) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/programComment/${programCommentId}`,
      data: { writerId, programId, contents },
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const deleteProgramComment = async ({ programCommentId, accessToken }) => {
  try {
    const options = {
      method: "DELETE",
      url: `/auth/programComment/${programCommentId}`,
      headers: { Authorization: accessToken },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const ProgramCommentAPI = {
  getProgramComments,
  createProgramComment,
  updateProgramComment,
  deleteProgramComment,
};

export default ProgramCommentAPI;
