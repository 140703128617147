import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserQueries from "../../components/query/UserQueries";
import LoginCheck from "../../components/LoginCheck";
import { useEffect } from "react";
import ProgramItem from "../../components/ProgramItem";
import DeleteIcon from "../../components/icons/DeleteIcon";
import EditIcon from "../../components/icons/EditIcon";
import { useState } from "react";
import { API } from "../../api/API";
import { useSelector } from "react-redux";
import { useRef } from "react";
import ProgramAddModal from "../../components/modals/ProgramAddModal";
import ProgramUpdateModal from "../../components/modals/ProgramUpdateModal";
import Frame from "../../components/Frame";
import { formatRunTime } from "../../utils/formater";
import ImageSelector from "../../components/ImageSelector";

const Lobby = () => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.userAuth);
  const { courseId } = useParams();

  const [editHead, setEditHead] = useState(false);

  const [course, setCourse] = useState();
  const [progress, setProgress] = useState(0);
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditdesctiption] = useState("");
  const [editBanner, setEditBanner] = useState();

  const [completer, setCompleter] = useState([]);

  const programAddState = useState(false);
  const [programAdd, setProgramAdd] = programAddState;

  const programUpdateState = useState(false);
  const programRef = useRef(null);
  const [programUpdate, setProgramUpdate] = programUpdateState;

  const titleInp = useRef();
  const descriptionInp = useRef();

  const settingCourse = (course) => {
    setCourse(course);
    setEditTitle(course.title);
    setEditdesctiption(course.description);
    setEditBanner(course.banner);
  };

  const updateCourse = async () => {
    let newBanner = course?.banner;
    if (typeof editBanner === "object") {
      const uploadBanner = await API.saveFile({
        accessToken,
        file: editBanner,
        path: `image/${new Date().valueOf()}.png`,
      });
      if (uploadBanner.success) newBanner = uploadBanner.data;
    }
    await API.updateCourse({
      courseId: courseId,
      title: editTitle,
      description: editDescription,
      banner: newBanner,
      accessToken,
    }).then((res) => {
      if (res.success) {
        settingCourse({
          ...course,
          title: editTitle,
          description: editDescription,
          banner: newBanner,
        });
        setEditHead(false);
      }
    });
  };

  const getCourse = async () => {
    await API.getCourse({ courseId: courseId, accessToken }).then((res) => {
      if (res.success) {
        settingCourse(res.data);
      }
    });
  };

  const deleteCourse = async () => {
    await API.deleteCourse({ courseId, accessToken }).then((res) => {
      navigate(-1);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (accessToken) {
      API.getCompleteUser({ courseId, accessToken }).then((res) => {
        if (res.success) {
          const formatData = res.data.map((user) => ({
            loginId: user?.loginId,
            name: user?.name,
            runtime: formatRunTime(user?.runTime),
          }));
          setCompleter(formatData);
        }
      });
    }
  }, [courseId, accessToken]);

  useEffect(() => {
    if (!programAdd || !programUpdate) {
      getCourse();
    }
  }, [courseId, accessToken, programAdd, programUpdate]);

  useEffect(() => {
    if (course?.programs?.length > 0) {
      setProgress(
        parseInt((course?.log?.progress * 100) / course?.programs?.length)
      );
    }
  }, [course]);

  const titleKeyPress = (key) => {
    if (key === "Enter") {
      descriptionInp.current.focus();
    }
  };

  const pressEditBtn = () => {
    if (editHead) {
      updateCourse();
    } else {
      setEditHead(true);
    }
  };

  const pressAddProgramBtn = () => {
    setProgramAdd(true);
  };

  const pressUpdateProgramBtn = (program) => {
    programRef.current = program;
    setProgramUpdate(program);
  };

  const pressDeleteBtn = () => {
    deleteCourse();
  };

  const pressDeleteProgram = (programId) => {
    API.deleteProgram({ programId: programId, accessToken }).then(() => {
      getCourse();
    });
  };

  return (
    <LoginCheck>
      <div className="w-[100vw] min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll px-auto lg:px-24">
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-col lg:flex-row mb-10">
            <ImageSelector
              disabled={!editHead}
              className="w-full h-96 bg-backBlue overflow-hidden"
              image={editHead ? editBanner : course?.banner}
              setImage={setEditBanner}
              resize={{ w: 500, h: 500 }}
            />
            <div className="w-full h-96 flex flex-col lg:ml-10">
              <div className="w-full my-2 h-12 flex">
                {editHead ? (
                  <input
                    ref={titleInp}
                    onKeyPress={(e) => titleKeyPress(e.key)}
                    value={editTitle}
                    type={"text"}
                    onChange={(e) => setEditTitle(e.target.value)}
                    className="text-center w-full bg-transparent border-0 outline-none text-2xl text-textGreen"
                  />
                ) : (
                  <p className="m-auto text-2xl text-textGreen">
                    {course?.title}
                  </p>
                )}
              </div>
              <div className="border-b-4 border-backGreen mb-5" />
              <div className="w-full h-40 px-5 flex overflow-y-scroll">
                {editHead ? (
                  <textarea
                    ref={descriptionInp}
                    value={editDescription}
                    onChange={(e) => setEditdesctiption(e.target.value)}
                    className="resize-none w-full bg-transparent border-0 outline-none text-xl text-textGreen"
                  />
                ) : (
                  <p className="text-xl text-textGreen whitespace-pre-wrap">
                    {course?.description}
                  </p>
                )}
              </div>
              <div className="border-t-4 border-backGreen mt-5 mb-3" />
              <div className="w-full flex my-auto h-8 border-4 border-backGreen bg-background relative">
                <div
                  className="h-full bg-backGreen flex"
                  style={{ width: `${progress}%` }}
                />
                <p className="text left-[50%] translate-x-[-50%] text-textBlue absolute">
                  {progress}%
                </p>
              </div>
              <UserQueries.NeedAdmin>
                <div className="w-full justify-end flex flex-row">
                  {editHead ? (
                    <Fragment>
                      <button
                        onClick={pressEditBtn}
                        className="h-8 w-24 bg-backGreen hover:opacity-60"
                      >
                        <p className="text-lg m-auto text-white">Complete</p>
                      </button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <button
                        onClick={pressEditBtn}
                        className="w-16 h-8 bg-backGreen hover:opacity-60"
                      >
                        <p className="text-lg m-auto text-white">Edit</p>
                      </button>
                      <button
                        onClick={pressDeleteBtn}
                        className="w-24 h-8 bg-backRed ml-2 hover:opacity-60"
                      >
                        <p className="text-lg m-auto text-white">Delete</p>
                      </button>
                    </Fragment>
                  )}
                </div>
              </UserQueries.NeedAdmin>
            </div>
          </div>
          <Frame
            title="Program"
            onPlusClick={pressAddProgramBtn}
            className="w-full"
          >
            {!course?.programs || course?.programs?.length === 0 ? (
              <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
                Program is Empty
              </p>
            ) : (
              course?.programs?.map((data, index) => (
                <div key={index} className="relative">
                  <ProgramItem
                    data={{
                      ...data,
                      courseTitle: course.title,
                      courseProgress: progress,
                      programs: course.programs,
                    }}
                    index={index}
                    link="program"
                  />
                  <UserQueries.NeedAdmin>
                    <button
                      onClick={() => pressUpdateProgramBtn(data)}
                      className="absolute z-10 right-12 top-[50%] translate-y-[-50%] hover:top-[40%] transition-all"
                    >
                      <EditIcon className={"m-auto stroke-textGreen"} />
                    </button>
                    <button
                      onClick={() => pressDeleteProgram(data._id)}
                      className="absolute z-10 right-4 top-[50%] translate-y-[-50%] hover:top-[40%] transition-all"
                    >
                      <DeleteIcon className={"m-auto stroke-textRed"} />
                    </button>
                  </UserQueries.NeedAdmin>
                </div>
              ))
            )}
          </Frame>
          <UserQueries.NeedAdmin>
            <Frame title="Complete Users" className="w-full mb-5">
              {completer.length === 0 ? (
                <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
                  Complete User is Empty
                </p>
              ) : (
                completer.map((user, index) => (
                  <div
                    key={index}
                    className="w-full h-10 flex my-1 hover:opacity-60"
                  >
                    <button className="w-full h-full">
                      <div className="w-full h-full flex flex-row">
                        <div className="my-auto ml-5">
                          <p className="text-textGreen text-lg lg:text-2xl">
                            {user.name}
                          </p>
                        </div>
                        <div className="my-auto ml-5">
                          <p className="text-textGreen text-lg lg:text-2xl">
                            {user.loginId}
                          </p>
                        </div>
                        <div className="my-auto ml-auto mr-5">
                          <p className="text-textGreen text-lg lg:text-2xl">
                            {user.runtime}
                          </p>
                        </div>
                      </div>
                    </button>
                  </div>
                ))
              )}
            </Frame>
          </UserQueries.NeedAdmin>
        </div>
        <ProgramAddModal modalState={programAddState} courseId={courseId} />
        <ProgramUpdateModal
          modalState={programUpdateState}
          programRef={programRef}
        />
      </div>
    </LoginCheck>
  );
};

export default Lobby;
