import { useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API } from "../../api/API";
import ImageSelector from "../../components/ImageSelector";
import LoginCheck from "../../components/LoginCheck";
import MediaQueries from "../../components/query/MediaQueries";

const CreateCourse = () => {
  const navigate = useNavigate();
  const { accessToken } = useSelector((state) => state.userAuth);
  const [banner, setBanner] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const nameInp = useRef();
  const descriptionInp = useRef();

  const [msg, setMsg] = useState("");

  const clickCreate = async () => {
    if (name === "") setMsg("Need Course Name");
    else if (description === "") setMsg("Need Course Description");
    else if (!banner) setMsg("Need Banner Image");
    else {
      const uploadBannerResult = await API.saveFile({
        accessToken,
        file: banner,
        path: `image/${new Date().valueOf()}.png`,
      });
      if (uploadBannerResult.success) {
        API.createCourse({
          title: name,
          description: description,
          banner: uploadBannerResult.data,
          accessToken,
        }).then((res) => {
          if (res.success) {
            setMsg("");
            navigate(`/course/${res.data._id}`, { replace: true });
          } else {
            setMsg("Create Course Fail");
          }
        });
      } else {
        setMsg("Upload Image Fail");
      }
    }
  };

  const nameKeyPress = (key) => {
    if (key === "Enter") {
      descriptionInp.current.focus();
    }
  };

  return (
    <LoginCheck needAdmin={true}>
      <div className="w-[100vw] min-h-[calc(100vh-20rem-2px)] flex flex-col bg-background">
        <div className="lg:mx-24 min-h-96 lg:my-10 my-auto flex flex-col bg-background border-4 border-backGreen">
          <MediaQueries.Default>
            <div className="h-14 w-full flex bg-backGreen">
              <p className="text-4xl m-auto text-textYellow">Create Content</p>
            </div>
          </MediaQueries.Default>
          <div className="flex flex-col p-5">
            <div className="flex flex-row mt-10">
              <p className="text-2xl lg:text-4xl text-textGreen">Title</p>
              <input
                className="w-full h-8 lg:h-10 ml-3 border-b-4 border-backGreen text-4xl outline-none text-textGreen"
                onChange={(e) => setName(e.target.value)}
                value={name}
                ref={nameInp}
                onKeyPress={(e) => nameKeyPress(e.key)}
              />
            </div>
            <div className="flex flex-col mt-10">
              <p className="text-2xl lg:text-4xl text-textGreen">Description</p>
              <textarea
                ref={descriptionInp}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="resize-none my-2 w-full h-32 outline-none px-5 py-2 border-4 border-backGreen text-xl text-textGreen"
              />
            </div>
          </div>
          <div className="px-5">
            <ImageSelector
              className="w-full lg:w-[40rem] h-[26rem] bg-backGreen mb-5 mt-3"
              image={banner}
              setImage={setBanner}
              disabled={false}
              resize={{ w: 500, h: 300 }}
            />
          </div>
          <p className="px-5 text-textRed text-xl">{msg}</p>
          <button
            onClick={clickCreate}
            className="mx-auto mb-5 w-32 bg-backGreen py-2 hover:bg-opacity-60"
          >
            <p className="m-auto text-2xl font-bold text-textYellow">Create</p>
          </button>
        </div>
      </div>
    </LoginCheck>
  );
};

export default CreateCourse;
