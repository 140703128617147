export const formatYoutube = (inp) => {
  const findQuery = inp.indexOf("watch?v=");
  if (findQuery !== -1) {
    return inp.substring(findQuery + 8);
  } else {
    const split = inp.split("/");
    return split[split.length - 1];
  }
};

export const formatYoutubeRev = (inp) => {
  return "https://youtu.be/" + inp;
};

export const formatDate = (inp, noTime = false) => {
  const inpDate = new Date(inp);
  const year = inpDate.getFullYear();
  const month = inpDate.getMonth() + 1;
  const date = inpDate.getDate();
  const hours = inpDate.getHours();
  const minutes = inpDate.getMinutes();
  return `${year}.${month}.${date}${noTime ? "" : ` ${hours}:${minutes}`}`;
};

export const formatRunTime = (inp) => {
  const hours = parseInt(inp / 3600000);
  inp = inp % 3600000;
  const minutes = parseInt(inp / 60000);
  inp = inp % 60000;
  const second = parseInt(inp / 1000);
  return `${hours}:${String(minutes).padStart(2, "0")}:${String(
    second
  ).padStart(2, "0")}`;
};
