import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "../api/API";

const LoginCheck = ({ children, needAdmin }) => {
  const userAuth = useSelector((state) => state.userAuth);
  const navigate = useNavigate();
  const location = useLocation();

  const [adminPass, setAdminPass] = useState(needAdmin ? false : true);

  useEffect(() => {
    if (!userAuth.isLogin) {
      navigate("/auth/login", {
        replace: true,
        state: { nextLink: location.pathname },
      });
    } else if (needAdmin) {
      API.checkAdmin({ accessToken: userAuth.accessToken }).then((res) => {
        if (res.success && res.data) {
          setAdminPass(true);
        } else {
          navigate("/error/need-admin", { replace: true });
        }
      });
    }
  });

  if (userAuth.isLogin && adminPass) {
    return children;
  } else {
    return (
      <div className="w-[100vw] min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll mb-10">
        <p className="text-textGreen text-3xl m-auto animate-bounce">
          Checking Admin Permission . . .
        </p>
      </div>
    );
  }
};

export default LoginCheck;
