import { ajax } from "./API";

const getNews = async () => {
  try {
    const options = {
      method: "GET",
      url: `/auth/news`,
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const createNews = async ({ accessToken, banner, link }) => {
  try {
    const options = {
      method: "POST",
      url: `/auth/news/create`,
      data: { banner, link },
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const updateNews = async ({ accessToken, newsId, banner, link }) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/news/${newsId}`,
      data: { banner, link },
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const deleteNews = async ({ accessToken, newsId }) => {
  try {
    const options = {
      method: "DELETE",
      url: `/auth/news/${newsId}`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const NewsAPI = {
  getNews,
  createNews,
  updateNews,
  deleteNews,
};

export default NewsAPI;
