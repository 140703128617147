import { ajax } from "./API";

const checkAdmin = async ({ accessToken }) => {
  try {
    const options = {
      method: "GET",
      url: `/auth/user/check-admin`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const setAdmin = async ({ accessToken, userId }) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/user/setAdmin/${userId}`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getAdmins = async ({ accessToken }) => {
  try {
    const options = {
      method: "GET",
      url: "/auth/user/get-admins",
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getUsers = async ({ accessToken }) => {
  try {
    const options = {
      method: "GET",
      url: "/auth/user/get-users",
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const UserAPI = { checkAdmin, setAdmin, getAdmins, getUsers };

export default UserAPI;
