import axios from "axios";
import CocommentAPI from "./CocommentAPI";
import CourseAPI from "./CourseAPI";
import NewsAPI from "./NewsAPI";
import NoticeAPI from "./NoticeAPI";
import ProgramAPI from "./ProgramAPI";
import ProgramCommentAPI from "./ProgramCommentAPI";
import StorageAPI from "./StorageAPI";
import UserAPI from "./UserAPI";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const ajax = async (options) => {
  let result;
  await axios(options)
    .then((response) => {
      result = { success: true, data: response?.data };
    })
    .catch((error) => {
      result = { success: false, data: error };
    });
  return result;
};

const login = async ({ loginId, password }) => {
  try {
    const options = {
      method: "POST",
      url: `/login`,
      data: { loginId, password },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const register = async ({ loginId, password, name }) => {
  try {
    const options = {
      method: "POST",
      url: `/register`,
      data: { loginId, password, name },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const saveFile = async ({ accessToken, file, path }) => {
  try {
    const options = {
      method: "POST",
      url: `/auth/storage/save`,
      headers: {
        Authorization: accessToken,
      },
      data: { file, path },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const API = {
  login,
  register,
  saveFile,
  ...UserAPI,
  ...CourseAPI,
  ...CocommentAPI,
  ...ProgramAPI,
  ...ProgramCommentAPI,
  ...NewsAPI,
  ...NoticeAPI,
  ...StorageAPI,
};
