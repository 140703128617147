import { ajax } from "./API";

const getAllCourse = async () => {
  try {
    const options = {
      method: "GET",
      url: `/auth/course/getAll`,
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getCourse = async ({ courseId, accessToken }) => {
  try {
    const options = {
      method: "GET",
      url: `/auth/course/${courseId}`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const getCompleteUser = async ({ courseId, accessToken }) => {
  try {
    const options = {
      method: "GET",
      url: `/auth/course/${courseId}/complete-user`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const createCourse = async ({ title, description, banner, accessToken }) => {
  try {
    const options = {
      method: "POST",
      url: `/auth/course/create`,
      data: { title, description, banner },
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const updateCourse = async ({
  courseId,
  title,
  description,
  banner,
  accessToken,
}) => {
  try {
    const options = {
      method: "PUT",
      url: `/auth/course/${courseId}`,
      data: { title, description, banner },
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const deleteCourse = async ({ courseId, accessToken }) => {
  try {
    const options = {
      method: "DELETE",
      url: `/auth/course/${courseId}`,
      headers: {
        Authorization: accessToken,
      },
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const CourseAPI = {
  getAllCourse,
  getCourse,
  getCompleteUser,
  createCourse,
  updateCourse,
  deleteCourse,
};

export default CourseAPI;
