import { ajax } from "./API";

const saveFile = ({ accessToken, file, path }) => {
  try {
    const formData = new FormData();
    formData.append("path", path);
    formData.append("file", file);
    const options = {
      method: "POST",
      url: "/auth/storage/save",
      headers: {
        Authorization: accessToken,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    return ajax(options);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

const StorageAPI = { saveFile };
export default StorageAPI;
