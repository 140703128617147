import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../api/API";
import Frame from "../../components/Frame";
import { formatDate } from "../../utils/formater";

const NoticeView = () => {
  const { accessToken } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();
  const { noticeId } = useParams();

  const [notice, setNotice] = useState();

  const pressEdit = () => {
    navigate(`/notice/${noticeId}/edit`);
  };

  const pressDelete = () => {
    API.deleteNotice({ accessToken, noticeId }).then((res) => {
      navigate(-1);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (accessToken) {
      API.getNotice({ noticeId }).then((res) => {
        if (res.success) {
          setNotice({
            ...res.data,
            createdAt: formatDate(res.data.createdAt),
          });
        }
      });
    }
  }, [noticeId, accessToken]);

  return (
    <div className="w-screen min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll mb-10">
      <Frame title={notice?.title} className="w-full lg:w-auto lg:mx-24">
        <div className="p-5 w-full min-h-[40rem] flex flex-col">
          <p className="text-lg lg:text-2xl text-textGreen whitespace-pre-line mb-auto">
            {notice?.contents}
          </p>
          <p className="ml-auto mt-5 text-lg lg:text-2xl text-textGreen">
            {notice?.createdAt}
          </p>
        </div>
      </Frame>
      <div className="w-full lg:w-auto lg:mx-24 flex mt-5">
        <button
          onClick={pressEdit}
          className="mt-5 ml-auto w-32 bg-backGreen py-2 hover:bg-opacity-60"
        >
          <p className="m-auto text-2xl font-bold text-textYellow">Edit</p>
        </button>
        <button
          onClick={pressDelete}
          className="mt-5 ml-5 w-32 bg-backRed py-2 hover:bg-opacity-60"
        >
          <p className="m-auto text-2xl font-bold text-textYellow">Delete</p>
        </button>
      </div>
    </div>
  );
};

export default NoticeView;
