import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "../../api/API";
import Frame from "../../components/Frame";
import LoginCheck from "../../components/LoginCheck";

const UserList = () => {
  const { accessToken } = useSelector((state) => state.userAuth);
  const [page] = useState(1);
  const searchNameState = useState("");
  const [searchName] = searchNameState;
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (accessToken) {
      API.getUsers({ accessToken }).then((res) => {
        if (res.success) {
          const formatData = res.data.map((user) => ({
            loginId: user?.loginId,
            name: user?.name,
          }));
          setUserList(
            formatData.filter((user) => user.name.includes(searchName))
          );
        }
      });
    }
  }, [accessToken, searchName]);

  return (
    <LoginCheck needAdmin={true}>
      <div className="w-[100vw] min-h-[calc(100vh-4rem-2px)] flex flex-col bg-background overflow-x-scroll">
        <Frame
          title={`Users ${page} page`}
          className="h-80 w-full lg:w-auto lg:mx-24"
          searchState={searchNameState}
        >
          {userList.length === 0 ? (
            <p className="text-center w-full my-auto text-2xl text-textGreen font-bold">
              Users is Empty
            </p>
          ) : (
            userList.map((user, index) => (
              <div
                key={index}
                className="w-full min-h-10 flex my-1 hover:opacity-60"
              >
                <div className="w-full h-full">
                  <div className="w-full h-full flex flex-row">
                    <div className="my-auto ml-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {user.name}
                      </p>
                    </div>
                    <div className="my-auto ml-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {user.loginId}
                      </p>
                    </div>
                    <div className="my-auto ml-auto mr-5">
                      <p className="text-textGreen text-lg lg:text-2xl">
                        {user.createdAt}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Frame>
      </div>
    </LoginCheck>
  );
};

export default UserList;
