import React, { Fragment, useEffect, useRef } from "react";
import LoginCheck from "../../components/LoginCheck";
import { useLocation, useNavigate } from "react-router-dom";
import ProgramSideView from "../../components/ProgramSideView";
import BackIcon from "../../components/icons/BackIcon";
import YouTube from "react-youtube";
import { API } from "../../api/API";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useLayoutEffect } from "react";
import Comment from "../../components/Comment";

const ProgramView = () => {
  const { accessToken, loginId } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();
  const location = useLocation();
  const { _id, title, video, log } = location.state;
  const color = log?.isComplete ? "textGreen" : "textRed";
  const colorCode = color === "textGreen" ? "#5DAE8B" : "#FF7676";
  const [newComment, setNewComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const startTime = useRef();

  const clickCommentCreate = () => {
    if (newComment !== "") {
      API.createProgramComment({
        accessToken,
        programId: _id,
        contents: newComment,
      }).then((result) => {
        setNewComment("");
        getComments();
      });
    }
  };

  const clickCommentDelete = (comment) => {
    API.deleteProgramComment({
      accessToken,
      programCommentId: comment._id,
    }).then((result) => {
      getComments();
    });
  };

  const getComments = async () => {
    await API.getProgramComments({ accessToken, programId: _id }).then(
      (result) => {
        if (result.success) {
          setCommentList(result.data);
        }
      }
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  const completeProgram = async () => {
    await API.completeProgram({ programId: _id, accessToken }).then((res) => {
      goBack();
    });
  };

  useEffect(() => {
    if (accessToken) {
      startTime.current = new Date();
    }
    return () => {
      API.endProgram({
        programId: _id,
        accessToken,
        progress: new Date() - startTime.current,
      });
    };
  }, [accessToken, video, _id]);

  useLayoutEffect(() => {
    getComments();
  }, []);

  return (
    <LoginCheck>
      <div className="w-[100vw] min-h-[calc(100vh-20rem-2px)] flex flex-row bg-background overflow-x-scroll">
        <ProgramSideView />
        <div className="lg:pl-[27rem] w-full">
          <div className="py-5 px-10 w-[96vw] lg:w-[57.6vw] flex flex-col mx-auto">
            <div className="flex flex-row w-full">
              <p
                className="text-xl lg:text-3xl mt-auto ml-auto"
                style={{ color: `${colorCode}` }}
              >
                {title}
              </p>
              <button onClick={goBack} className="ml-auto rounded-xl flex">
                <BackIcon
                  className="mx-auto my-auto"
                  style={{ stroke: `${colorCode}` }}
                />
              </button>
            </div>
            <div className="w-full h-[54vw] lg:h-[32.4vw] bg-black mx-auto mt-10">
              <YouTube
                className="w-full h-[54vw] lg:h-[32.4vw]"
                videoId={video}
                opts={{
                  width: "100%",
                  height: "100%",
                  playerVars: {
                    autoplay: 1,
                    rel: 0,
                    modestbranding: 1,
                  },
                }}
                onEnd={() => {
                  completeProgram();
                }}
              />
            </div>
            <div className="w-full my-10">
              <div className="bg-background my-5 flex flex-col border-4 border-backGreen">
                <p className="px-3 py-2 text-2xl text-textYellow w-full bg-backGreen">
                  {loginId}
                </p>
                <div className="mx-5 my-3">
                  <textarea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    className="resize-none 
                                            w-full h-32 p-3
                                            bg-transparent 
                                            outline-none border-backGreen border-2
                                            text-textGreen text-xl"
                  />
                </div>
                <button
                  onClick={clickCommentCreate}
                  className="mr-5 ml-auto mb-3 w-28 h-10 bg-backGreen"
                >
                  <p className="text-xl text-textYellow">Comment</p>
                </button>
              </div>
              {commentList.map((comment) => (
                <Fragment>
                  <Comment comment={comment} onDelete={clickCommentDelete} />
                  {comment.cocomments.map((cocoment) => (
                    <div className="ml-20">
                      <Comment comment={cocoment} />
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </LoginCheck>
  );
};

export default ProgramView;
